module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Toms-Bud Strona Firmowa","short_name":"Toms-Bud","start_url":"/","background_color":"#f27e00","theme_color":"#f27e00","display":"minimal-ui","icon":"src/images/toms-bud-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Inter"]}},
    }]
